import { CartLineItemAccessoryCard } from "@components/Cart/LineItems/CartLineItemAccessoryCard";
import CartLineItemCard from "@components/Cart/LineItems/CartLineItemCard";
import { CartReturnsPolicy } from "@components/Cart/Returns/CartReturnsPolicy";
import CartTotals from "@components/Cart/Totals/CartTotals";
import { Icon } from "@components/Icon";
import ModalsDrawer from "@components/Modals/ModalsDrawer";
import { ProductProvider } from "@providers/localised/product";
import React from "react";
import tw, { styled } from "twin.macro";
import { withCartDrawer } from "./withCartDrawer";
import { Button, Callout } from "@bared/ui";

const ActionTitle = styled.p`
  ${tw`text-white p-mini bg-primary`}
  span {
    vertical-align: middle;
    margin-right: 6px;
  }
`;

const StyledIcon = styled(Icon)`
  ${tw`text-white`}
  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const CartDrawer = withCartDrawer(
  ({
    activeCart,
    cartItemsQuantity,
    cartUrl,
    checkoutUrl,
    emptyTitle,
    handleCartActive,
    handleCheckout,
    keepShopping,
    lineItemsAccessoryMap,
    lineItemsProducts,
    loading,
    title,
    totalItems,
    cartTitleRef,
    cartPaymentRef,
    cartLineItemsRef,
  }) => {
    return (
      <ModalsDrawer
        active={
          typeof activeCart !== "boolean" ? activeCart?.activeCart : activeCart
        }
        setActive={handleCartActive}
        right={true}
        data-testid="cart-drawer"
      >
        <div className="pb-0 relative h-full overflow-x-hidden bg-neutral">
          <h2
            className="my-0 bg-primary bg-opacity-10 p-mini text-left font-display text-[18px] leading-h2 md:leading-h4"
            ref={cartTitleRef}
          >
            {cartItemsQuantity > 0 ? title : emptyTitle}
          </h2>

          {cartItemsQuantity > 0 ? (
            <>
              <div
                className="overflow-y-auto overflow-x-hidden"
                ref={cartLineItemsRef}
              >
                {typeof activeCart !== "boolean" && activeCart.newItemAdded && (
                  <ActionTitle>
                    <StyledIcon name="success" size="xxs" />
                    <span>Item Added to Cart</span>
                  </ActionTitle>
                )}
                <CartReturnsPolicy />
                <div>
                  {lineItemsProducts?.map((lineItem, index) => {
                    const { variant } = lineItem;
                    const { id } = variant;

                    if (!lineItemsAccessoryMap?.[id]) {
                      return (
                        <ProductProvider
                          stub={variant.product}
                          key={`cart-item-no-accessory-${lineItem.id}`}
                        >
                          <CartLineItemCard
                            data-testid="cart-item-no-accessory"
                            isLast={index === totalItems - 1}
                            lineItem={lineItem}
                            layout="drawer"
                          />
                        </ProductProvider>
                      );
                    }

                    return (
                      <>
                        <ProductProvider
                          stub={variant.product}
                          key={`cart-item-with-accessory-${lineItem.id}`}
                        >
                          <CartLineItemCard
                            data-testid="cart-item-with-accessory"
                            isLast={index === totalItems - 1}
                            lineItem={lineItem}
                            layout="drawer"
                          />
                        </ProductProvider>
                        {lineItemsAccessoryMap[id].map((lineItemAccessory) => (
                          <CartLineItemAccessoryCard
                            data-testid="cart-item-accessory"
                            key={`cart-item-accessory-${lineItemAccessory.id}`}
                            lineItem={lineItemAccessory}
                            layout="drawer"
                          />
                        ))}
                      </>
                    );
                  })}
                </div>
              </div>

              <div
                className="absolute bottom-zero flex w-auto flex-col space-y-micro border-t border-primary bg-neutral p-mini"
                ref={cartPaymentRef}
              >
                <CartTotals layout="drawer" />

                <div onClick={handleCheckout}>
                  <Button variant="primary" size="large" href={checkoutUrl}>
                    {loading ? "Loading..." : "Checkout"}
                  </Button>
                </div>

                <div
                  style={{ width: "fit-content" }}
                  onClick={handleCartActive}
                >
                  <Button
                    className="!justify-start"
                    variant="link"
                    href={cartUrl}
                  >
                    View Bag
                  </Button>
                </div>

                <Callout variant="secondary" size="small" className="bg-white">
                  Looking for Additional Notes? Add these at the final step of
                  checkout.
                </Callout>
              </div>
            </>
          ) : (
            <div className="px-midi pt-midi">
              <Button
                variant="primary"
                size="large"
                onClick={handleCartActive}
                className="w-full"
              >
                {keepShopping.title}
              </Button>
            </div>
          )}
        </div>
      </ModalsDrawer>
    );
  },
);

export default CartDrawer;
