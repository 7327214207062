import React from "react";

import Money from "@components/Money/Money";

import { withCartTotals } from "./withCartTotals";

const CartTotals = withCartTotals(
  ({
    amountDue,
    className,
    layout,
    subTotal,
    discountApplied,
    currencyCode,
  }) => {
    return (
      <div className={className}>
        {layout === `cart` && (
          <div className="flex w-full justify-between">
            <p className="text-hint leading-hint">Subtotal</p>
            <Money className="text-hint leading-hint" price={subTotal} />
          </div>
        )}
        {discountApplied > 0 && (
          <div className="flex w-full justify-between">
            <p className="text-hint leading-hint">Discount</p>
            <Money
              className="text-hint leading-hint"
              refund={true}
              price={{
                amount: discountApplied,
                currencyCode,
              }}
            />
          </div>
        )}
        {layout === `cart` && (
          <div className="my-mini h-[1px] w-full bg-primary" />
        )}
        <div className="flex w-full justify-between">
          <p className="text-hint font-medium leading-hint">Total</p>
          <Money
            className="text-hint font-medium leading-hint"
            price={amountDue}
          />
        </div>
      </div>
    );
  },
);

export default CartTotals;
