import { useCheckoutContext } from "@hooks/useCheckout";
import { ComponentProps } from "@ts/components";
import { CurrencyCode, MoneyV2 } from "@ts/shopify-storefront";
import React, { FC } from "react";

export type CartTotalsInputProps = ComponentProps & {
  layout: string;
};

export type CartTotalsOutputProps = CartTotalsInputProps & {
  amountDue: MoneyV2;
  subTotal: MoneyV2;
  currencyCode: CurrencyCode;
  discountApplied: number;
};

export const withCartTotals =
  (Component: FC<CartTotalsOutputProps>) =>
  ({
    name = "CartTotals",
    className,
    layout = "cart",
  }: CartTotalsInputProps) => {
    const { checkout } = useCheckoutContext();

    const amountDue = checkout?.paymentDueV2;
    const subTotal = checkout?.lineItemsSubtotalPrice;
    const currencyCode = checkout?.totalPriceV2?.currencyCode;
    const discountApplied =
      parseFloat(checkout?.lineItemsSubtotalPrice?.amount) -
      parseFloat(checkout?.totalPriceV2?.amount);

    Component.displayName = name;
    return (
      <Component
        amountDue={amountDue}
        className={className}
        currencyCode={currencyCode}
        discountApplied={discountApplied}
        layout={layout}
        subTotal={subTotal}
      />
    );
  };
