import { useAnalytics } from "@hooks/useAnalytics";
import { useApp } from "@hooks/useApp";
import { useCart } from "@hooks/useCart";
import { useProduct } from "@hooks/useProduct";
import { useShopify } from "@hooks/useShopify";
import { type ComponentProps } from "@ts/components";
import {
  type CheckoutLineItem,
  type Image,
  type Maybe,
  type MoneyV2,
} from "@ts/shopify-storefront";
import React, { useCallback, type FC } from "react";

type CartLineItemAccessoryCardInputProps = ComponentProps & {
  layout: "cart" | "drawer";
  lineItem: CheckoutLineItem;
};

type CartLineItemAccessoryCardOutputProps = Omit<
  CartLineItemAccessoryCardInputProps,
  "lineItem"
> & {
  handleLineItemRemove: () => void;
  image?: Maybe<Image>;
  loading: boolean;
  prefix?: string;
  price?: MoneyV2;
  quantity: number;
  suffix?: string;
  title: string;
};

export const withCartLineItemAccessoryCard =
  (Component: FC<CartLineItemAccessoryCardOutputProps>) =>
  ({
    name = "CartLineItemAccessoryCard",
    lineItem,
    ...rest
  }: CartLineItemAccessoryCardInputProps) => {
    const {
      config: {
        settings: { keys },
      },
    } = useApp();
    const { trackEvent } = useAnalytics();
    const { productNormaliser } = useShopify();
    const { getCartAffixesFromTags } = useProduct();
    const {
      loading,
      loadingAttributes,
      removeLineItemFromCart,
      setLoading,
      updateAttributes,
    } = useCart();
    const { title: rawTitle, quantity, variant, customAttributes } = lineItem;
    const { image, priceV2: price, product } = variant || {};
    const { tags } = productNormaliser(product);
    const { prefix, suffix } = getCartAffixesFromTags(tags);

    const parentVariantId = customAttributes?.find(
      (attr) => attr.key === keys.accessory_line_item_link,
    )?.value;

    const handleLineItemRemove = useCallback(async () => {
      try {
        await updateAttributes(parentVariantId, {
          [`${keys.accessory_removed}_${product.handle}`]: title,
        });
        await removeLineItemFromCart(lineItem.id);
      } finally {
        trackEvent("remove_accessory", {
          handle: lineItem.variant?.product.handle,
        });
        setLoading(false);
      }
    }, [lineItem]);

    /** Determine if this accessory is a fit kit. */
    const isFitKit = rawTitle.toLowerCase().includes("fit kit");

    /** Determine if this accessory is free. */
    const isFree = 0 === Number(price?.amount);

    /**
     * If this is a free fit kit, force the title to say "Complimentary Fit Kit"
     * and disable the link to PDP.
     */
    const title = isFitKit && isFree ? "Complimentary Fit Kit" : rawTitle;

    Component.displayName = name;

    return (
      <Component
        {...rest}
        handleLineItemRemove={handleLineItemRemove}
        image={image}
        loading={loading || loadingAttributes}
        prefix={prefix}
        price={price}
        quantity={quantity}
        suffix={suffix}
        title={title}
      />
    );
  };
