import { useAnalytics } from "@hooks/useAnalytics";
import { useApp } from "@hooks/useApp";
import { useCheckout, useCheckoutContext } from "@hooks/useCheckout";
import { useFunctions } from "@hooks/useFunctions";
import { usePage } from "@hooks/usePage";
import { ComponentProps } from "@ts/components";
import { Internal } from "@ts/sanity";
import { CheckoutLineItem } from "@ts/shopify-storefront";
import React, {
  FC,
  MouseEvent,
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useCustomer } from "~/hooks/useSession";

export type CartDrawerInputProps = ComponentProps;

export type CartDrawerOutputProps = {
  activeCart: boolean | { activeCart: boolean; newItemAdded: boolean };
  cartItemsQuantity: number;
  cartUrl: string;
  checkoutUrl: string;
  emptyTitle: string;
  handleCartActive: () => void;
  handleCheckout: (e: MouseEvent<HTMLDivElement>) => Promise<void>;
  keepShopping: Internal;
  lineItemsAccessoryMap: Record<string, CheckoutLineItem[]>;
  lineItemsProducts: CheckoutLineItem[];
  loading: boolean;
  newItemAdded: boolean;
  title: string;
  totalItems: number;
  cartTitleRef: MutableRefObject<HTMLHeadingElement | undefined>;
  cartPaymentRef: MutableRefObject<HTMLDivElement | undefined>;
  cartLineItemsRef: MutableRefObject<HTMLDivElement | undefined>;
};

export const withCartDrawer = (Component: FC<CartDrawerOutputProps>) =>
  memo(({ name = "CartDrawer" }: CartDrawerInputProps) => {
    const { checkoutUrl } = useCheckout();
    const { checkout } = useCheckoutContext();
    const { calculateLineItemsQuantityTotal } = useCheckout();
    const { loading, checkoutMultipass } = useFunctions();
    const { trackCartView } = useAnalytics();

    const customer = useCustomer();

    const {
      cart: { title, emptyTitle, keepShopping },
    } = usePage();

    const {
      config: {
        settings: { routes },
      },
      globalStateReducer,
    } = useApp();

    const [{ activeCart, newItemAdded }, dispatch] = globalStateReducer;
    const cartUrl = `${routes.CART}`;
    const { lineItems, lineItemsAccessoryMap, lineItemsProducts } =
      checkout || {};
    const quantity = calculateLineItemsQuantityTotal();
    const totalItems = lineItems?.length;

    const cartTitleRef = useRef<HTMLHeadingElement | undefined>();
    const cartPaymentRef = useRef<HTMLDivElement | undefined>();
    const cartLineItemsRef = useRef<HTMLDivElement | undefined>();

    const handleCartActive = useCallback(() => {
      dispatch({
        type: "setActiveCart",
        payload: !activeCart
          ? { activeCart: !activeCart, newItemAdded: false }
          : false,
      });
    }, [activeCart]);

    const handleCheckout = useCallback(
      async (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        await checkoutMultipass(customer?.email, checkout.id, checkoutUrl);
      },
      [customer, checkout, checkoutUrl, checkoutMultipass],
    );

    useEffect(() => {
      const setCartlineItemsContainerHeight = () => {
        const cartDrawer = document.querySelector(
          '[data-testid="cart-drawer"]',
        );

        if (!cartLineItemsRef.current) return;

        cartLineItemsRef.current.style.setProperty(
          "max-height",
          `calc(100vh - ${cartDrawer?.getBoundingClientRect().top}px - ${cartPaymentRef.current?.clientHeight}px - ${cartTitleRef.current?.clientHeight}px)`,
        );
      };

      if (activeCart) {
        trackCartView();
        setCartlineItemsContainerHeight();

        // Disables background scrolling whilst the minicart modal is open
        if (typeof window != "undefined" && window.document) {
          document.body.style.overflow = "hidden";
        }
      } else {
        document.body.style.removeProperty("overflow");
      }

      window.addEventListener("resize", setCartlineItemsContainerHeight);

      return () =>
        window.removeEventListener("resize", setCartlineItemsContainerHeight);
    }, [activeCart]);

    // Toggle data attribute used with CSS to show/hide elements which conflict with the cart drawer.
    React.useEffect(() => {
      document.documentElement.dataset.cartOpen = activeCart ? "true" : "false";
    }, [activeCart]);

    Component.displayName = name;

    return useMemo(
      () => (
        <Component
          activeCart={activeCart}
          cartItemsQuantity={quantity}
          cartUrl={cartUrl}
          checkoutUrl={checkoutUrl}
          emptyTitle={emptyTitle}
          handleCartActive={handleCartActive}
          handleCheckout={handleCheckout}
          lineItemsAccessoryMap={lineItemsAccessoryMap}
          lineItemsProducts={lineItemsProducts}
          keepShopping={keepShopping}
          loading={loading}
          newItemAdded={newItemAdded}
          title={title}
          totalItems={totalItems}
          cartTitleRef={cartTitleRef}
          cartPaymentRef={cartPaymentRef}
          cartLineItemsRef={cartLineItemsRef}
        />
      ),
      [
        activeCart,
        cartUrl,
        checkoutUrl,
        emptyTitle,
        handleCartActive,
        keepShopping,
        lineItemsAccessoryMap,
        lineItemsProducts,
        loading,
        newItemAdded,
        quantity,
        title,
        totalItems,
      ],
    );
  });
