import React, { FC } from "react";

import { useCheckoutContext } from "@hooks/useCheckout";
import { usePage } from "@hooks/usePage";
import { useShopify } from "@hooks/useShopify";
import { ComponentProps } from "@ts/components";

export type CartReturnsPolicyInputProps = ComponentProps;

export type CartReturnsPolicyOutputProps = CartReturnsPolicyInputProps & {
  finalSaleItemReturnPolicy: string;
  hasFinalSaleItems: boolean;
  hasSaleItems: boolean;
  saleItemReturnPolicy: string;
};

export const withCartReturnsPolicy =
  (Component: FC<CartReturnsPolicyOutputProps>) =>
  ({ name = "CartReturnsPolicy", className }: CartReturnsPolicyInputProps) => {
    const { checkout } = useCheckoutContext();
    const {
      cart: { saleItemReturnPolicy, finalSaleItemReturnPolicy },
    } = usePage();

    const { getBadgeFromTags } = useShopify();
    const { lineItems } = checkout || {};
    const hasSaleItems = lineItems.reduce((prev, curr) => {
      const hasSaleAttribute = curr.customAttributes.some(
        ({ key, value }) => key === "_return_policy" && value === "sale",
      );
      return prev || hasSaleAttribute;
    }, false);
    const hasFinalSaleItems = lineItems.reduce((prev, curr) => {
      const hasFinalSaleAttribute = curr.customAttributes.some(
        ({ key, value }) => key === "_return_policy" && value === "final_sale",
      );
      return prev || hasFinalSaleAttribute;
    }, false);

    Component.displayName = name;

    if (
      (!saleItemReturnPolicy || !hasSaleItems) &&
      (!finalSaleItemReturnPolicy || !hasFinalSaleItems)
    ) {
      return null;
    }

    return (
      <Component
        className={className}
        saleItemReturnPolicy={saleItemReturnPolicy}
        hasSaleItems={hasSaleItems}
        finalSaleItemReturnPolicy={finalSaleItemReturnPolicy}
        hasFinalSaleItems={hasFinalSaleItems}
      />
    );
  };
