import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing";
import { Icon } from "@components/Icon";
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import React, { memo } from "react";
import tw from "twin.macro";
import { ModalsDrawerOutputProps, withModalsDrawer } from "./withModalsDrawer";

const Container = styledWithInvalidProps("div")(
  ({ active }: { active: boolean }) => [
    tw`fixed top-0 left-0 right-0 z-[9999999] transition-layout top-9 md:top-[122px]`,
    active ? tw`visible duration-slow` : tw`invisible`,
  ],
);

const Bg = styledWithInvalidProps("div")(({ active }: { active: boolean }) => [
  tw`fixed bottom-0 left-0 right-0 cursor-pointer transition-layout bg-primary top-9 md:top-[122px]`,
  active ? tw`opacity-90 duration-slow` : tw`opacity-0`,
]);

const ContentWrapper = styledWithInvalidProps("div")(
  ({ right, active }: { right: boolean; active: boolean }) => [
    tw`absolute z-30 w-full h-full transition-layout max-w-[490px] min-h-full-vh`,
    right ? tw`right-0` : null,
    active ? tw`opacity-100` : tw`opacity-0`,
  ],
);

const Close = withFocusRing(
  styledWithInvalidProps("button")(({ noClose }: { noClose: boolean }) => [
    tw`absolute right-0 z-10 cursor-pointer top-pico hover:opacity-70`,
    noClose ? tw`hidden` : null,
  ]),
);

const Content = tw.div`
  h-full overflow-x-hidden overflow-y-auto bg-white pb-[86px] md:pb-[123px]
`;

const StyledIcon = tw(Icon)`block pt-micro pr-micro`;

const ModalsDrawer = withModalsDrawer(
  ({
    active,
    handleClick,
    children,
    right,
    noClose,
    "data-testid": dataTestId,
  }: ModalsDrawerOutputProps) => {
    return (
      <Container active={active} data-testid={dataTestId}>
        <Bg active={active} onClick={handleClick} />
        <ContentWrapper active={active} right={right}>
          <Content>
            <Close noClose={noClose} onClick={handleClick} title="close">
              <StyledIcon name="close" size="small" />
            </Close>
            {children}
          </Content>
        </ContentWrapper>
      </Container>
    );
  },
);

export default memo(ModalsDrawer);
