/** @todo Use path alias when gatsby dead  */
import { sites } from "../config/sites";

/**
 * Configuration for a single "site".
 */
export interface Site {
  /**
   * Unique handle for site.
   * @example "au"
   */
  handle: string;
  /**
   * URL prefix at which site is hosted.
   * @example "au"
   */
  urlPrefix: string;
  /**
   * Local identifier.
   * @example "en-AU"
   */
  locale: string;
  /**
   * Currency code to show on site.
   * @example "AUD"
   */
  currencyCode: string;
  /**
   * The "primary" country code associated with the site.
   *
   * Note that this does not guarantee the customer is actually
   * viewing from this country.
   *
   * This is used for the Storefront API `@inContext` directive
   * and other places which need a single country code.
   *
   * @example "AU"
   */
  countryCode: string;
  /**
   * The language code associated with the site.
   *
   * This is used for the Storefront API `@inContext` directive
   *
   * @example "EN"
   */
  languageCode: string;
  /**
   * Array of country codes which should be directed to this site.
   *
   * You may include a "ROW" country code here, which will be used as a fallback site for
   * country codes which do not have a dedicated site.
   *
   * @example ["AU", "NZ"]
   */
  countryCodes: string[];
  /**
   * ID for the "Site" Shopify metaobject associated with this site.
   *
   * The metaobject is used for referencing sites in a metafield. For example, the
   * "Exclude sites" metafield will return an array of these IDs.
   *
   * In GraphQL queries, we can expand the metaobject to get the handle, but some
   * clients (like Reactify Search) only give us the ID.
   *
   * @example "gid://shopify/Metaobject/106424565896"
   * @see https://bared-melbourne.slack.com/archives/C045GT6BNAU/p1736485977377519?thread_ts=1736465111.362969&cid=C045GT6BNAU
   */
  metaobjectId: string;
}

/**
 * Return site for the given country code.
 */
export function resolveSiteFromCountryCode({
  countryCode,
}: {
  countryCode: string;
}) {
  const site = sites.find((site) => site.countryCodes.includes(countryCode));
  if (site) return site;

  const rowSite = sites.find((site) => site.countryCodes.includes("ROW"));
  if (rowSite) return rowSite;

  throw new Error(`Could not resolve site for country code ${countryCode}.`);
}

/**
 * Return site for the given handle.
 */
export function resolveSiteFromHandle({ handle }: { handle: string }) {
  const site = sites.find((site) => site.handle === handle);
  if (site) return site;
  throw new Error(`Could not resolve site for handle ${handle}.`);
}

/**
 * Return site for the given URL prefix.
 */
export function resolveSiteFromUrlPrefix({ urlPrefix }: { urlPrefix: string }) {
  const site = sites.find((site) => site.urlPrefix === urlPrefix);
  if (site) return site;
  throw new Error(`Could not resolve site for URL prefix ${urlPrefix}.`);
}

/**
 * Map a site to a legacy "locale" object.
 */
export function mapSiteToLegacyLocale(site: Site): LegacyLocale {
  return {
    allowedCountryCodes: site.countryCodes,
    // If the url prefix is empty, then base route is mapped to "" NOT "/" ¯\_(ツ)_/¯
    baseRoute: site.urlPrefix ? `/${site.urlPrefix}` : "",
    countryCodeCurrencyMap: {
      [site.countryCode]: site.currencyCode,
    },
    defaultCountryCode: site.countryCode,
    defaultCurrency: site.currencyCode,
    enabled: true,
    hrefLang: site.locale,
    languageCode: site.locale,
    isDefault: "us" === site.handle,
  };
}

/**
 * Legacy "locale" object which used to be included in config.
 * We map the new "Site" objects to this for backwards compatibility.
 */
export interface LegacyLocale {
  /** @example ["AU"] */
  allowedCountryCodes: string[];
  /** @example "/au" */
  baseRoute: string;
  /** @example {AU: "AUD"} */
  countryCodeCurrencyMap: Record<string, string>;
  /** @example "AU" */
  defaultCountryCode: string;
  /** @example "AUD" */
  defaultCurrency: string;
  /** @example true */
  enabled: boolean;
  /** @example "en-au" */
  hrefLang: string;
  /** @example "en-AU" */
  languageCode: string;
  /** @example true */
  isDefault: boolean;
}
