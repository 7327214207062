import React from "react";

import { Callout } from "@bared/ui";

import { withCartReturnsPolicy } from "./withCartReturnsPolicy";

export const CartReturnsPolicy = withCartReturnsPolicy(
  ({
    className,
    hasSaleItems,
    hasFinalSaleItems,
    saleItemReturnPolicy,
    finalSaleItemReturnPolicy,
  }) => (
    <div className={className}>
      {hasSaleItems || hasFinalSaleItems ? (
        <Callout variant="warning" size="small">
          {hasFinalSaleItems ? finalSaleItemReturnPolicy : saleItemReturnPolicy}
        </Callout>
      ) : null}
    </div>
  ),
);
