import React from "react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing";
import FormDropdown from "@components/Form/FormDropdown";
import { Icon } from "@components/Icon";
import { Image } from "@components/Image/Image";
import { Link } from "@components/Link/Link";
import { ImageWrapper } from "@components/Styled/ImageWrapper";
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps";
import { type DropdownOption } from "@hooks/useSelector";
import { SelectedOption } from "@ts/shopify-storefront";
import { CartLineItemPrice } from "./CartLineItemPrice";
import {
  CartLineItemCardOutputProps,
  withCartLineItemCard,
} from "./withCartLineItemCard";

type SizeSelectorProps = {
  options: DropdownOption[];
  selectedOptions: SelectedOption;
  handleSelect: (value: string) => void;
};

type QuantitySelectorProps = {
  layout: "cart" | "drawer";
  singleVariant: boolean;
  loading: boolean;
  quantity: number;
  onUpdateQuantity: (qty: number) => () => void;
};

const Wrapper = styledWithInvalidProps("div")(({ isLast, loading, layout }) => [
  tw`flex flex-wrap w-full bg-neutral relative`,
  !isLast ? tw`border-b border-tertiary` : null,
  layout === `cart` ? tw`p-midi` : tw`p-mini`,
  loading ? tw`opacity-50` : null,
]);

const Remove = styledWithInvalidProps(Icon)(({ layout }) => [
  tw`absolute cursor-pointer -mr-pico`,
  layout === `cart`
    ? tw`top-mini right-mini md:top-midi md:right-midi`
    : tw`top-mini right-mini md:top-mini md:right-mini`,
]);

const Desktop = styledWithInvalidProps("div")(({ layout }) => [
  tw`flex-row justify-between items-center`,
  layout === "cart" ? tw`hidden mb-0 lg:flex` : tw`flex mb-nano`,
]);

const Mobile = tw.div`
  w-full flex flex-col mt-pico lg:hidden 
`;

const InfosWrapper = styledWithInvalidProps("div")(({ layout }) => [
  tw`flex-1 flex flex-col`,
  layout !== "cart" ? tw`justify-between` : null,
]);

const Title = styledWithInvalidProps("span")(({ layout }) => [
  tw`inline cursor-pointer`,
  layout === "cart"
    ? tw`mr-mini lg:mr-maxi`
    : tw`mr-mini lg:mr-0 mb-midi lg:mb-maxi md:pr-mega`,
]);

const Text = styledWithInvalidProps("p")(({ layout, bold }) => [
  tw`inline capitalize leading-h4`,
  layout === "cart" ? tw`text-hint lg:text-body` : tw`text-[13px]`,
  bold ? tw`font-medium` : null,
]);

const SelectorsWrapper = styledWithInvalidProps("div")(({ layout }) => [
  tw`flex flex-col`,
  layout === "cart" ? tw`w-full` : null,
]);

const Selectors = tw.div`
  w-full flex flex-row lg:w-auto lg:mt-0
`;

const SizeSelector = tw(FormDropdown)`
  w-3/5 text-hint mr-pico cursor-pointer lg:w-4/6 
`;

const QuantitySelector = styledWithInvalidProps("div")(
  ({ layout, singleVariant }) => [
    tw`border border-primary flex items-center justify-between overflow-hidden`,
    layout === "cart" && singleVariant ? tw`w-2/5 h-mega lg:w-2/6` : null,
    layout === "cart" && !singleVariant
      ? tw`w-2/5 h-mega ml-pico lg:w-2/6 md:ml-mini`
      : null,
    layout === "drawer" ? tw`w-[106px] h-midi lg:w-[96px]` : null,
  ],
);

const QuantityAmount = tw.p`
  text-hint leading-caption
`;

const QuantityButton = withFocusRing(styled.button`
  ${tw`p-micro h-full bg-primary flex items-center justify-center transition-opacity hover:opacity-50`}
  ${({ layout }) => (layout === "cart" ? tw`w-maxi` : tw`w-midi`)}
`);

const StyledIcon = tw(Icon)`text-white`;

const Badge = styledWithInvalidProps("span")(({ colour, layout }) => [
  tw`inline-block text-caption p-micro self-start mb-pico md:text-caption`,
  colour === `dark` ? tw`text-white bg-secondary` : tw`text-primary bg-white`,
  layout === `drawer` ? tw`-mt-midi` : tw``,
]);

const ItemImageWrapper = styledWithInvalidProps(ImageWrapper)(({ layout }) => [
  tw`mr-mini`,
  layout === "cart" ? tw`w-[90px] lg:w-[96px]` : tw`w-[90px]`,
]);

const SizeSelectorDropdown = ({
  options,
  selectedOptions,
  handleSelect,
}: SizeSelectorProps) => (
  <SizeSelector
    name="cartLineItem-sizeSelector"
    options={options}
    value={selectedOptions?.value}
    placeholder="Select Size"
    handleChange={handleSelect}
    layout="sizes"
  />
);

const QuantitySelectorButton = ({
  layout,
  singleVariant,
  loading,
  quantity,
  onUpdateQuantity,
}: QuantitySelectorProps) => (
  <QuantitySelector
    layout={layout}
    singleVariant={singleVariant}
    data-testid={"quantity-selector"}
  >
    <QuantityButton
      disabled={loading || quantity <= 1}
      onClick={onUpdateQuantity(-1)}
      layout={layout}
      data-testid="cart-line-item-card-qty-decrement"
    >
      <StyledIcon name="minusSmall" size="none" height="24" width="8" />
    </QuantityButton>
    <QuantityAmount>{quantity}</QuantityAmount>
    <QuantityButton
      disabled={loading}
      onClick={onUpdateQuantity(1)}
      layout={layout}
      data-testid="cart-line-item-card-qty-increment"
    >
      <StyledIcon name="plusSmall" size="none" height="24" width="8" />
    </QuantityButton>
  </QuantitySelector>
);

const CartLineItemCard = withCartLineItemCard(
  ({
    "data-testid": dataTestId,
    className,
    handleLineItemRemove,
    handleSelect,
    isLast,
    layout,
    lineItem: {
      link,
      image,
      title,
      subTitle,
      quantity,
      price,
      compareAtPrice,
      selectedOptions,
      customAttributes,
      discountAllocationsAmount,
      isPreOrder,
      isGiftCard,
    },
    loading,
    onUpdateQuantity,
    options,
    singleVariant,
  }: CartLineItemCardOutputProps) => {
    return (
      <Wrapper
        data-testid={dataTestId}
        className={className}
        isLast={isLast}
        layout={layout}
        loading={loading}
      >
        <Remove
          data-testid="cart-line-item-card-remove"
          name="close"
          size="xsmall"
          layout={layout}
          onClick={handleLineItemRemove}
        />
        <ItemImageWrapper
          image={image}
          layout={layout}
          as={link ? Link : null}
          to={link}
        >
          <Image alt={title} image={image} ratio="11/12" maxWidth={150} />
        </ItemImageWrapper>
        <InfosWrapper layout={layout}>
          <Title layout={layout} as={Link} to={link}>
            <Text bold layout={layout}>
              {title}
            </Text>
            <Text layout={layout}>{subTitle}</Text>
          </Title>
          {isPreOrder ? (
            <Badge colour="dark" layout={layout}>
              Pre-order
            </Badge>
          ) : null}
          {layout === "cart" && (
            <CartLineItemPrice
              className={clsx(
                "leading-h4",
                layout === "cart" && "mb-midi mt-pico text-hint lg:text-body",
              )}
              price={price}
              compareAtPrice={compareAtPrice}
              discountAllocationsAmount={discountAllocationsAmount}
              layout={layout}
            />
          )}
          <Desktop layout={layout}>
            <SelectorsWrapper layout={layout}>
              <Selectors>
                {layout === "cart" && !singleVariant && !isGiftCard ? (
                  <SizeSelectorDropdown
                    options={options}
                    selectedOptions={selectedOptions}
                    handleSelect={handleSelect}
                  />
                ) : null}
                {!isGiftCard ? (
                  <QuantitySelectorButton
                    layout={layout}
                    singleVariant={singleVariant}
                    loading={loading}
                    quantity={quantity}
                    onUpdateQuantity={onUpdateQuantity}
                  />
                ) : (
                  <Text layout={layout}>Quantity: {quantity}</Text>
                )}
              </Selectors>
            </SelectorsWrapper>
            {layout === "drawer" && (
              <>
                {selectedOptions?.name && !isGiftCard ? (
                  <Text layout={layout}>
                    {selectedOptions.name}: {selectedOptions.value}
                  </Text>
                ) : null}
                <CartLineItemPrice
                  className={clsx(
                    "leading-h4",
                    layout === "drawer" && "text-[13px]",
                  )}
                  price={price}
                  compareAtPrice={compareAtPrice}
                  discountAllocationsAmount={discountAllocationsAmount}
                />
              </>
            )}
          </Desktop>
        </InfosWrapper>
        {layout === "cart" && (
          <Mobile>
            <Selectors>
              {!singleVariant || !isGiftCard ? (
                <SizeSelectorDropdown
                  options={options}
                  selectedOptions={selectedOptions}
                  handleSelect={handleSelect}
                />
              ) : null}
              {!isGiftCard ? (
                <QuantitySelectorButton
                  layout={layout}
                  singleVariant={singleVariant}
                  loading={loading}
                  quantity={quantity}
                  onUpdateQuantity={onUpdateQuantity}
                />
              ) : null}
            </Selectors>
          </Mobile>
        )}
      </Wrapper>
    );
  },
);

export default CartLineItemCard;
